import * as React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

class AboutPage extends React.Component {
  componentDidMount() {
    var dropdowns = document.querySelectorAll('.dropdown');
    for (let i = 0; i < dropdowns.length; i++) {
      dropdowns[i].addEventListener('click', function(e){
        var dropdown = e.target.closest('.dropdown');
        var height = dropdown.querySelector('.inner').offsetHeight;
        if(dropdown.classList.contains('active')) {
          dropdown.classList.remove('active');
          dropdown.querySelector('.content').style.height = '0px';
        } else {
          dropdown.classList.add('active');
          dropdown.querySelector('.content').style.height = height+'px';
        }
      });
    }
  }
  render() {
    const {data} = this.props
    return (
    <Layout>
      <Seo title="About TGMC" /> 
      <div className="hero-banner h-100 mh-50 flex m-wrap">
        <div className="w-50 m-100 m-hide flex align-center">
          <div className="title-area p20 max-750 pos-rel z-2">
            <h1 className="m0 fade-right" data-sal data-sal-delay="500">{data.allDatoCmsAbout.edges[0].node.bannerTitle}</h1>
          </div>
        </div>
        <div className="w-50 m-100 pos-rel">
          <GatsbyImage image={data.allDatoCmsAbout.edges[0].node.bannerImage.gatsbyImageData} alt="About GM" className="bg-image" />
        </div>
      </div>

      <div className="bg-yellow p20">
        <div className="title-area p20 max-750 pos-rel z-2 m-show">
          <h1 className="m0 fade-right" data-sal data-sal-delay="500">{data.allDatoCmsAbout.edges[0].node.bannerTitle}</h1>
        </div>
        <div className="spacer m-show"></div>
        <p className="m0 h1 mh2 max-1100 mt20 fade-in" data-sal data-sal-delay="300">{data.allDatoCmsAbout.edges[0].node.introductionTitle}</p>
        <div className="spacer m-hide"></div>
        <div className="spacer m-hide"></div>
      </div>

      <div className="our-company flex m-wrap">
        <div className="w-40 m-100 p20">
          <p className="h3 m0 text-yellow mt40 fade-right" data-sal data-sal-delay="300">Our Company</p>
        </div>
        <div className="w-60 m-100 p20 fade-in" data-sal data-sal-delay="500">
          <p className="m0 h2 mt40">
            {data.allDatoCmsAbout.edges[0].node.ourCompany}
          </p>
        </div>
      </div>

      <div className="our-company flex mt40 m-wrap">
        <div className="w-40 m-100 p20"> 
          <p className="h3 m0 text-yellow mt40 fade-right" data-sal data-sal-delay="300">Our Industry Knowledge</p>
        </div>
        <div className="w-60 m-100 p20 fade-in" data-sal data-sal-delay="500">
          <p className="m0 h2 mt40">
            {data.allDatoCmsAbout.edges[0].node.industryKnowledge}
          </p>
        </div>
      </div>

      <div className="dropdown-content p20 fade-in" data-sal data-sal-delay="500">
        <div className="dropdown">
          <div className="title">
            {data.allDatoCmsAbout.edges[0].node.dropdownTitle1}
          </div>
          <div className="content op-50">
            <div className="inner pb20" dangerouslySetInnerHTML={{ __html:data.allDatoCmsAbout.edges[0].node.dropdownContent1 }} />
          </div>
        </div>

        <div className="dropdown">
          <div className="title">
            {data.allDatoCmsAbout.edges[0].node.dropdownTitle2}
          </div>
          <div className="content op-50">
            <div className="inner pb20" dangerouslySetInnerHTML={{ __html:data.allDatoCmsAbout.edges[0].node.dropdownContent2 }} />
          </div>
        </div>

        <div className="dropdown">
          <div className="title">
            {data.allDatoCmsAbout.edges[0].node.dropdownTitle3}
          </div>
          <div className="content op-50">
            <div className="inner pb20" dangerouslySetInnerHTML={{ __html:data.allDatoCmsAbout.edges[0].node.dropdownContent3 }} />
          </div>
        </div>

        <div className="dropdown">
          <div className="title">
            {data.allDatoCmsAbout.edges[0].node.dropdownTitle4}
          </div>
          <div className="content op-50">
            <div className="inner pb20" dangerouslySetInnerHTML={{ __html:data.allDatoCmsAbout.edges[0].node.dropdownContent4 }} />
          </div>
        </div>
      </div>

      <div className="spacer half"></div>

      <div className="director-section p20 flex m-wrap m-reverse">
        <div className="w-50 m-100">
          <p className="h3 m0 text-yellow mt40 fade-right m-hide" data-sal data-sal-delay="300">{data.allDatoCmsAbout.edges[0].node.directorTitle}</p>  
          <div className="h2 mt40 max-700 fade-in" data-sal data-sal-delay="500">
            {data.allDatoCmsAbout.edges[0].node.directorIntroduction}
          </div>
          <div className="op-50 max-700 mt40 fade-in" data-sal data-sal-delay="500">
            {data.allDatoCmsAbout.edges[0].node.directorContent}
          </div>
          <div className="mt40 fade-in" data-sal data-sal-delay="500">
            <Link to="https://www.linkedin.com/in/troytodd" target="_blank" rel="noreferrer" className="link with-arrow">Troy’s Linkedin</Link>
          </div>
          <div className="mt10 fade-in" data-sal data-sal-delay="500">
            <Link to="https://www.linkedin.com/company/the-gm-company" target="_blank" rel="noreferrer" className="link with-arrow">The GM Company Linkedin</Link>
          </div>
        </div>
        <div className="w-50 m-100">
          <p className="h3 m0 text-yellow mt40 fade-right m-show m-mb40" data-sal data-sal-delay="300">Our Director and <br/>Lead Practitioner</p>
          <div className="max-600 ma">
            <div className="ratio-3-2 bg-grey pos-rel">
              <GatsbyImage image={data.allDatoCmsAbout.edges[0].node.directorImage.gatsbyImageData} alt="About GM" className="bg-image" />
            </div>
          </div>
        </div>
      </div> 

      <div className="spacer"></div>

      <div className="image-banner flex flex-wrap align-center mh-80 p20 pos-rel ">
        <div className="title-area p20 text-white w-100 pos-rel z-2 align-bottom">
          <h1 className="m0">
            <span className="fade-right inline" data-sal data-sal-delay="300">{data.allDatoCmsAbout.edges[0].node.ctaTitleOne}</span>
            <br/><span className="fade-right inline" data-sal-delay="500" data-sal>{data.allDatoCmsAbout.edges[0].node.ctaTitleTwo}</span>
          </h1>
        </div>
        <div className="align-bottom pos-rel z-2 w-100 p20 text-white fade-in" data-sal data-sal-delay="500">
          <Link to="/contact" className="link with-arrow h3">Contact Us</Link> 
        </div>
        <GatsbyImage image={data.allDatoCmsAbout.edges[0].node.ctaImage.gatsbyImageData} alt="About GM" className="bg-image" />
      </div>
    </Layout>
    )
  }
}

export const aboutQuery = graphql`
  query AboutQuery {
    allDatoCmsAbout {
      edges {
        node {
          bannerTitle
          bannerImage {
            gatsbyImageData(placeholder:DOMINANT_COLOR)
          }
          introductionTitle
          ourCompany
          industryKnowledge
          directorTitle
          directorIntroduction
          directorContent
          directorImage {
            gatsbyImageData(placeholder:DOMINANT_COLOR)
          }
          dropdownTitle1
          dropdownContent1
          dropdownTitle2
          dropdownContent2
          dropdownTitle3
          dropdownContent3
          dropdownTitle4
          dropdownContent4
          ctaTitleOne
          ctaTitleTwo
          ctaImage {
            gatsbyImageData(placeholder:DOMINANT_COLOR)
          }
        }
      }
    }
  }
`

export default AboutPage
